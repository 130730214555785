import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2021-stories/more-stories"
import Link from "gatsby-plugin-transition-link/AniLink";
// import { Parallax, Background } from 'react-parallax';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import socialBanner from "../../images/fall-winter-2021/social-banners/uncovering-memories-from-world-war-ii.jpg";

// import hero from "../../images/fall-winter-2021/uncovering-memories-from-world-war-ii/last-day-at-ht-mt-nov-1945.jpg";


import heartMountain from "../../images/fall-winter-2021/uncovering-memories-from-world-war-ii/heart-mountain.jpg";
import masayukiAndYukikoYoshida from "../../images/fall-winter-2021/uncovering-memories-from-world-war-ii/masayuki-and-yukiko-yoshida.jpg";
import yukikoYoshida from "../../images/fall-winter-2021/uncovering-memories-from-world-war-ii/yukiko-yoshida.jpg";

import theyCalledUsEnemy from "../../images/fall-winter-2021/uncovering-memories-from-world-war-ii/they-called-us-enemy.jpg";

import coph from '../../images/fall-winter-2021/coph-timeline/hero2.jpg';
import coph1 from "../../images/fall-winter-2021/uncovering-memories-from-world-war-ii/coph.jpg";

import cawthraGuiaFousekisHelen from "../../images/fall-winter-2021/uncovering-memories-from-world-war-ii/cawthra-guia-fousekis-helen.jpg";

export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'Uncovering Memories From World War II';
    var pageDesc = 'A history alumna chronicles Japanese American experiences for future generations.';
    var slug = 'uncovering-memories-from-world-war-ii';


    var heroStyles = {
      backgroundColor: '#222',
      backgroundImage: `url(${masayukiAndYukikoYoshida})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      color: 'white',
      height: '100vh'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2021">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
            <p className="sub-heading animated fadeInDown delay-1s">A history alumna chronicles Japanese American experiences for future generations.</p>
            <span className="animated fadeInDown delay-1s byline">By Kendra Morales, Photos courtesy of Helen Yoshida</span>
          </div>
          <div className="tint"></div>
        </div>
        <article className="wrap small">

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">

          <p>One of Helen Yoshida’s early memories is of a painting on the living room wall of her childhood home. Passed down from her grandmother to her father, the night landscape featured a full moon illuminating a city at the base of a mountain.</p>

          <p>The Cal State Fullerton history alumna later learned that this “city” was instead multiple barracks sprawled across the plain. It was the site of the Heart Mountain incarceration camp in Wyoming, where nearly 14,000 people of Japanese ancestry — including Yoshida’s grandparents — were imprisoned during World War II.</p>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">

          <figure className="align-wide">
            <img src={heartMountain} alt="" />
            <figcaption>“Heart Mountain” by Jishiro Miyauchi. Photo Courtesy of the Heart Mountain Interpretive Center</figcaption>
          </figure>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">

          <p>“Issei (first-generation Japanese American) artist Jishiro Miyauchi gave it to my grandmother when they were at Heart Mountain,” Yoshida says. “I didn’t really think about that painting again until I wrote my undergraduate thesis, a collection of short stories set at that camp. The more I wrote, the more I realized that I wanted to visit this place to see where they were incarcerated during the war, to see what was there now.”</p>
          
          <p>Yoshida’s grandfather died before she got a chance to meet him. Her grandmother, before she died, mentioned very little about life at Heart Mountain. So Yoshida collects and researches her family’s memorabilia to learn more about their past, gleaned from handwritten notes and photographs taken from the 1930s to the 1980s.</p>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">

          <figure className="align-left large align-outside">
            <img src={yukikoYoshida} alt="" />
            <figcaption>Yukiko Yoshida</figcaption>
          </figure>
          
          <p>“She did not dwell on the racism and mass hysteria that had driven her there,” Yoshida writes of her grandmother in a 2014 essay in <a href="https://www.theatlantic.com/education/archive/2014/07/a-seamstress-of-circumstance/373618/" target="_blank" rel="noreferrer">The Atlantic</a>. “Instead, she described how she’d spent the time: working, pursuing her interests, making lifelong friends and meeting her future husband. Just as she fashioned new clothing from fabric remnants, she used every opportunity to craft a comfortable life for herself.”</p>

          <p>Heart Mountain is one of 10 incarceration camps and other historically significant sites where Japanese Americans were confined during WWII. These locations are now protected, restored and interpreted by the National Park Service and organizations across the country like the Heart Mountain Wyoming Foundation.</p> 

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">

          <p>Yoshida eventually made it to Heart Mountain and spent a brief period working for the foundation, for which she recorded Japanese American memories on such topics as the events of Pearl Harbor. </p>

          <p>“I met people who were formerly incarcerated who really wanted to tell their stories and felt like their contributions weren't recognized,” Yoshida explains. “Oral history was a good way to help them share their stories, record this history and expand the narrative.”</p>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">

          <BrowserView>
          <aside className="related-stories align-outside">
            <h2>Related Story</h2>
            <Link
            className="related-link"
            cover
            bg="#FF7900"
            direction="up"
            duration={1}
            duration={.75}
            to="/fall-winter-2021/coph-timeline/" >
              <img src={coph} alt="" className="link-image" />
              <span className="link-text">Through the Years: A Center for Oral and Public History Timeline</span>
            </Link>
          </aside>
          </BrowserView>
          
          <h2>Training as a Historian</h2>
          <p>Yoshida ’19 (M.A. history-public history) was drawn to Cal State Fullerton’s graduate history program and the <a href="http://coph.fullerton.edu" target="_blank" rel="noreferrer">Lawrence de Graaf Center for Oral and Public History</a>, specifically its <a href="http://coph.fullerton.edu/collections/JAcollections.php" target="_blank" rel="noreferrer">Japanese American Oral History Project</a>, which has an archive of more than 300 oral histories pertaining to the WWII Japanese American evacuation.</p>

          <p>“Cal State Fullerton is one of the best programs and the best fit for me,” Yoshida says.</p>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">

          <figure className="align-left align-outside large">
            <img src={coph1} alt="Lawrence de Graaf Center for Oral and Public History" />
          </figure>

          <p>Its oral history program is student-driven, meaning Yoshida was able to record more oral histories of Japanese Americans formerly incarcerated during WWII. Her research focused on the World War II Enemy Alien Control Program, a federal program that authorized imprisonment of U.S. residents of German, Italian, Japanese or Latin American ancestry until 1948. </p>

          <p>Yoshida traveled the country to record seven oral history interviews with wartime incarcerees and descendants of the Fort Missoula (Montana), Santa Fe (New Mexico) and Crystal City (Texas) WWII Department of Justice camps, in addition to working as a graduate archival assistant for COPH.</p>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">

          <p>“CSUF’s oral history program taught me how to ask reflective questions, actively listen to others’ narratives and merge storytelling with historical analysis,” Yoshida shares. “It gave me the freedom to create, plan and complete my own project with the guidance and encouragement of professors Natalie Fousekis, Benjamin Cawthra, Aitana Guia and Arthur Hansen.” </p>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">

          <figure className="align-wide">
            <img src={cawthraGuiaFousekisHelen} alt="" />
            <figcaption>Helen Yoshida ’19 (M.A. history-public history), with CSUF history faculty members Benjamin Cawthra, Aitana Guia and Natalie Fousekis</figcaption>
          </figure>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">

          <p>Through this work, Yoshida hopes the public will see themselves reflected in the personal experiences of wartime incarcerees, and understand how trauma from racist and unjust policies and programs in U.S. history affects families across generations.</p>

          <p>“There will be a time when we won't have incarcerees or their descendants who can talk about it,” Yoshida says. “Exhibits and the oral histories that COPH has in its collections will be all the more important. This will be the record for future generations to learn from.”</p>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">

          <aside className="related-stories align-outside">
            <h2>One Book, One CSUF</h2>
            <img src={theyCalledUsEnemy} alt="They Called Us Enemy" style={{marginTop: '-10px'}} />
            <p style={{marginBottom: '0'}}>Part of Cal State Fullerton's <a href="http://together.fullerton.edu/" target="_blank" rel="noreferrer">Titans Together</a> initiative, <a href="http://together.fullerton.edu/one-book-one-csuf/" target="_blank" rel="noreferrer">One Book, One CSUF</a> explores themes of diversity, inclusion and equity with the CSUF community through book discussions. The 2021-22 selection is George Takei’s “They Called Us Enemy,” a graphic memoir recounting Takei’s childhood imprisoned within American incarceration camps during World War II.</p>
          </aside>
          
          <h2>Understanding Japanese American Perspectives</h2>
          <p>After earning her master’s degree, Yoshida was approached by <a href="https://www.nationalww2museum.org/" target="_blank" rel="noreferrer">The National WWII Museum</a> in New Orleans to join their team as a scriptwriter and consultant. Yoshida says her work with COPH helped her develop the expertise and confidence to work on a range of projects.</p>

          <p>This year, Yoshida helped create an electronic field trip for middle and high school students called the <a href="https://www.nationalww2museum.org/students-teachers/distance-learning/k-12-distance-learning/electronic-field-trips/japanese-american" target="_blank" rel="noreferrer">“Japanese American Experiences in WWII Electronic Field Trip.”</a> The webcast follows two student reporters as they review Japanese American oral histories with former incarcerees from the Heart Mountain, Rohwer and Jerome (Arkansas) camps. She also wrote an article for the museum titled <a href="https://www.nationalww2museum.org/war/articles/redress-and-reparations-japanese-american-incarceration" target="_blank" rel="noreferrer">“Redress and Reparations for Japanese American Incarceration.”</a></p>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">

          <p>On display this winter at COPH will be an exhibit on Japanese American history, curated by Yoshida. The exhibit, titled “The Iharas: A Typical (Japanese) American Family,” focuses on the family history of Craig Ihara, CSUF professor emeritus of philosophy, who was born at the Rohwer Japanese American Relocation Center in 1943.</p>

          <p>Yoshida hopes the exhibit helps students realize the value of COPH’s collections so they can incorporate oral history into their own research.</p>

          <p>“I also hope it inspires students to delve into their community and family histories.” <span className="end-mark" /></p>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">

          <p className="learn-more">Support the College of Humanities and Social Sciences<br /><a href="https://campaign.fullerton.edu/hss" target="_blank">campaign.fullerton.edu/hss</a></p>

          </ScrollAnimation>

          <MobileView>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <aside className="related-stories align-outside">
              <h2>Related Story</h2>
              <Link
              className="related-link"
              cover
              bg="#FF7900"
              direction="up"
              duration={1}
              duration={.75}
              to="/fall-winter-2021/coph-timeline/" >
                <img src={coph} alt="" className="link-image" />
                <span className="link-text">Through the Years: A Center for Oral and Public History Timeline</span>
              </Link>
            </aside>
          </ScrollAnimation>
          </MobileView>

        </article>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 